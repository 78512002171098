
import { Component, Vue } from 'vue-property-decorator'
import { CultureList } from '@/types/school.d'

@Component({
  name: 'cultureList'
})
export default class extends Vue {
  private info = {
    name: '',
    status: ''
  }

  private loading = false
  private tableData: CultureList[] = []
  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<CultureList> }>(this.$apis.school.selectCampusScienceByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  getStatus (status: string) {
    const list = ['待发布', '发布中', '已下架']
    return list[Number(status)]
  }

  onAdd () {
    this.$router.push({
      name: 'cultureAdd'
    })
  }

  // 发布/下架
  onOperate (row: CultureList) {
    const text = row.status === '0' ? '发布' : '下架'
    this.$confirm('确认' + text + '吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      const status = row.status === '0' ? '1' : '2'
      this.$axios.post(this.$apis.school.updateScienceStatus, {
        scienceId: row.scienceId,
        status: status
      }).then(() => {
        this.$message.success(text + '成功')
        this.onSearch()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'cultureEdit',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.school.deleteCampusScience, {
        scienceId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'cultureDetail',
      params: { id }
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
